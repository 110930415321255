export const mobileScreenWidth = 600
export const languages = ["de", "en"]
export const placeholderImage = "https://shary-icons.s3.eu-central-1.amazonaws.com/image_placeholder.png"

// Social Links
export const facebookLink = "https://www.facebook.com/sharyrentalaustria"
export const instagramLink = "https://www.instagram.com/shary_rental/"
export const linkedinLink = "https://www.linkedin.com/company/sharyrental/"

// Store Links
export const appStoreLink = "https://apps.apple.com/at/app/shary/id1641155238"
export const playMarketLink = "https://play.google.com/store/apps/details?id=at.shary&hl=de"

// Development link
export const developmentLink = "https://api-dev.shary.com/"
