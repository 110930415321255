import styles from "./Button.module.css"
import {CSSProperties} from "react";
import BeatLoader from "react-spinners/BeatLoader";
import Link from "next/link";

// Images
import chatIcon from "../../../img/components/shared/button/chat.png";

interface IButtonProps {
	text: string;
	submit?: boolean;
	disabled?: boolean;
	styleType: "outlined" | "primary" | "text";
	onClick?: () => void;
	style?: CSSProperties;
	loading?: boolean;
	link?: boolean;
	linkUrl?: string;
	id?: string;
	"data-testid"?: string;
	icon?: "chat";
}

export default function Button(props:IButtonProps) {

	const getIcon = () => {
		switch (props.icon) {
			case "chat":
				return {
					icon: chatIcon.src,
					alt: "chat",
					title: "chat"
				}
			default:
				return {
					icon: chatIcon.src,
					alt: "chat",
					title: "chat"
				}
		}
	}

	if (props.styleType === "outlined") {
		return(
			props.link
				? <Link
					href={props.linkUrl ?? "/"}
					data-testid={props["data-testid"]}
				>
					<button
						type={props.submit ? "submit" : "button"}
						disabled={props.disabled || props.loading}
						className={styles.outlined_button}
						style={props.style ? props.style : {}}
						id={props.id}
					>
						{props.loading
							? <BeatLoader
								size={8}
							/>
							: props.text
						}
					</button>
				</Link>
				: <button
					type={props.submit ? "submit" : "button"}
					disabled={props.disabled || props.loading}
					className={styles.outlined_button}
					onClick={() => props.onClick ? props.onClick() : null}
					style={props.style ? props.style : {}}
					id={props.id}
					data-testid={props["data-testid"]}
				>
					<div className={"row"}>
						{props.icon &&
							<img
								src={getIcon().icon}
								alt={getIcon().alt}
								title={getIcon().title}
							/>
						}
						{props.loading
							? <BeatLoader
								size={8}
							/>
							: <p>
								{props.text}
							</p>
						}
					</div>
				</button>
		)
	} else if (props.styleType === "primary") {
		return (
			props.link
				? <Link
					href={props.linkUrl ?? "/"}
					data-testid={props["data-testid"]}
				>
					<button
						type={props.submit ? "submit" : "button"}
						disabled={props.disabled || props.loading}
						className={styles.primary_button}
						style={props.style ? props.style : {}}
						id={props.id}
					>
						{props.loading
							? <BeatLoader
								size={8}
								color={"#ffffff"}
							/>
							: props.text
						}
					</button>
				</Link>
				: <button
					type={props.submit ? "submit" : "button"}
					className={`${styles.primary_button} ${props.disabled ? styles.disabled : ""}`}
					onClick={() => props.onClick && !props.disabled && !props.loading ? props.onClick() : null}
					style={props.style ? props.style : {}}
					disabled={props.disabled || props.loading}
					id={props.id}
					data-testid={props["data-testid"]}
				>
					{props.loading
						? <BeatLoader
							size={8}
							color={"#ffffff"}
						/>
						: props.text
					}
				</button>
		)
	} else if(props.styleType === "text") {
		return (
			props.link
				? <Link
					href={props.linkUrl ?? "/"}
					id={props.id}
					data-testid={props["data-testid"]}
				>
					<p
						className={styles.text_button}
						style={props.style ? props.style : {}}
					>
						{props.loading
							? <BeatLoader
								size={8}
							/>
							: props.text
						}
					</p>
				</Link>
				: <p
					className={styles.text_button}
					onClick={() => props.onClick ? props.onClick() : null}
					style={props.style ? props.style : {}}
					id={props.id}
					data-testid={props["data-testid"]}
				>
					{props.loading
						? <BeatLoader
							size={8}
						/>
						: props.text
					}
				</p>
		)
	} else {
		return(<></>)
	}

}
