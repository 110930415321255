import Head from "next/head";
import {useEffectOnce} from "../../../hooks/useEffectOnce";
import UserService from "../../../services/User/user.service";
import {useTranslation} from "next-i18next";
import {currencyFormatter} from "../../../utils/currencyFormatter/currencyFormatter";
import Script from "next/script";
import injectKeywordsToHtml from "../../../utils/injectKeywordsToHtml/injectKeywordsToHtml";

export type customProductGoogleSnippetType = {
	productName: string;
	productImage: string;
	productDescription: string;
	productPrice: number;
	productRating: number;
}

interface IProps {
	title: string;
	description: string;
	image?: string;
	withCustomSnippet?: any;
	customSnippetData?: customProductGoogleSnippetType;
	customSnippetProductsData?: customProductGoogleSnippetType[];
	customElement?: any;
	city?: string;
}

export default function SEO(props:IProps) {
	const { i18n } = useTranslation()

	// Set default language
	useEffectOnce(() => {
		const userLanguage = new UserService().getUserLanguage();
		if (i18n.language !== userLanguage) {
			i18n.changeLanguage(userLanguage);
		}
	})

	// @ts-ignore
	return(
		<Head>

			{process.env.REACT_APP_ENV === "development"
				? <meta name="robots" content="noindex,nofollow" />
				: <meta name="robots" content="index,follow" />
			}

			{
				props.customElement && props.customElement
			}


			<Script id="google-analytics" strategy="afterInteractive">
				{`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-T8ZB7RK');
        `}
			</Script>

			<meta name="viewport" content="initial-scale=1.0, width=device-width, user-scalable=no" />
			<meta name="theme-color" content="#1D2741" />
			<link rel="manifest" href="/static/manifest/manifest.json" />
			<link rel="icon" type="image/x-icon" href="https://shary-icons.s3.eu-central-1.amazonaws.com/favicon.ico"/>
			<link rel="apple-touch-icon" href="https://shary-icons.s3.eu-central-1.amazonaws.com/apple-touch.png" />

			<title>{injectKeywordsToHtml({htmlContent: props.title, city: props?.city})}</title>
			<meta name="description" content={injectKeywordsToHtml({htmlContent: props.description, city: props?.city})} />
			<meta name="author" content="shary" />

			<meta property="og:site_name" content="Shary" />
			<meta property="og:title" content={props.title} />
			<meta property="og:url" content={"shary.at"} />
			<meta property="og:image" content={props.image ?? "https://shary-icons.s3.eu-central-1.amazonaws.com/open_graph.png"} />
			<meta property="og:description" content={props.description} />
			<meta property="og:type" content="website" />

			<meta name="twitter:title" content={props.title} />
			<meta name="twitter:description" content={props.description} />
			<meta name="twitter:image" content={props.image ?? "https://shary-icons.s3.eu-central-1.amazonaws.com/open_graph.png"} />
			<meta name="twitter:card" content="summary_large_image" />

			<meta name="apple-itunes-app" content="app-id=1641155238, app-argument=https://shary.at" />

			{props.withCustomSnippet && props.customSnippetProductsData && props.customSnippetProductsData.length > 0
				? <script type="application/ld+json" dangerouslySetInnerHTML={{__html: `
        			[
        			${props.customSnippetProductsData.map((item, idx) => {
						return `
						{
  						"@context": "http://schema.org",
  						"@type": "Product",
  						"name": "${item.productName}",
  						"image": "${item.productImage}",
  						"description": "${item.productDescription}",
  						"offers": {
    						"@type": "Offer",
    						"price": "${item.productPrice}",
    						"priceCurrency": "EUR"
  						},
  						"review": {
 				 			"@type": "Review",
    						"reviewRating": {
      							"@type": "Rating",
      							"ratingValue": "${item.productRating}",
      							"bestRating": "${item.productRating}"
    						},
    						"author": {
      							"@type": "Company",
      							"name": "Shary"
    						}
  						}
  					}
						`
					})}
        			]
    `}}>
				</script>
				: null
			}

			{props.withCustomSnippet && props.customSnippetData
				? <script type="application/ld+json" dangerouslySetInnerHTML={{__html: `
        			{
  						"@context": "http://schema.org",
  						"@type": "Product",
  						"name": "${props.customSnippetData.productName.replace(/(\r\n|\n|\r)/gm, "")}",
  						"author": "Shary",
  						"image": "${props.customSnippetData.productImage}",
  						"description": "${props.customSnippetData.productDescription.replace(/(\r\n|\n|\r)/gm, "")}",
  						"offers": {
    						"@type": "Offer",
    						"price": "${props.customSnippetData.productPrice}",
    						"priceCurrency": "EUR"
  						},
  						"review": {
 				 			"@type": "Review",
    						"reviewRating": {
      							"@type": "Rating",
      							"ratingValue": "${props.customSnippetData.productRating}",
      							"bestRating": "${props.customSnippetData.productRating}"
    						},
    						"author": {
      							"@type": "Company",
      							"name": "Shary"
    						}
  						}
				}
    `}}>
				</script>
				: null
			}

		</Head>
	)
}
