interface IParams {
	htmlContent: string;
	city?: string;
}

export default function injectKeywordsToHtml(props: IParams) {

	const capitalizeFirstLetters = (string: string) => {
		let wortArray = string.toLowerCase().split(" ");

		for (let i = 0; i < wortArray.length; i++) {
			wortArray[i] = wortArray[i].charAt(0).toUpperCase() + wortArray[i].slice(1);
		}

		return wortArray.join(" ");
	}

	const mapObj:any = {
		"{{city}}": capitalizeFirstLetters(props.city || "")
	};

	return props.htmlContent.replace(/{{city}}/gi, function(matched){
		return mapObj[matched];
	});

}
