import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyA6SnxK4gbgJOQ5SRJkbfkTnUrj_5MePdk");
Geocode.enableDebug();

export async function cityNameToGeoCode(cityName: string) {
	return Geocode.fromAddress(cityName).then(
		(response) => {
			const { lat, lng } = response.results[0].geometry.location;
			return {
				status: true,
				lat: lat,
				lng: lng
			}
		},
		(error) => {
			return {
				status: false,
				error: error
			}
		}
	);
}
