import React, {useEffect, useState} from "react";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import {cityNameToGeoCode} from "../../../utils/cityToGeocode/cityToGeocode";
import locationIcon from "../../../img/home/home/location_marker.png"
import {useTranslation} from "next-i18next";
import {motion} from "framer-motion";
import styles from "../Search/Search.module.css";
import errorStyles from "../FormikInput/FormikInput.module.css";

export default function Autocomplete(props) {
	const { t } = useTranslation()

	const googleParams = {
		options: {
			types: [props.address ? "address" : "(cities)"],
			componentRestrictions: { country: "at" },
			language: "de"
		}}
	if (!props.withoutGoogleKey) {
		googleParams.apiKey = "AIzaSyA6SnxK4gbgJOQ5SRJkbfkTnUrj_5MePdk"
	}

	const {
		placesService,
		placePredictions,
		getPlacePredictions,
		isPlacePredictionsLoading,
	} = useGoogle(googleParams);

	const [value, setValue] = useState("");
	const [showError, setShowError] = useState(false);

	const [showLocationsMenu, setShowLocationsMenu] = useState(false);

	const onPlaceSelected = async (place, index) => {
		let coords;
		try {
			coords = await cityNameToGeoCode(place.description) // place.structured_formatting.main_text
		} catch (e) {
			setShowLocationsMenu(false);
			setShowError(true);
			props.onError();
		}

		if (!coords.status) {
			setShowLocationsMenu(false);
			setShowError(true);
			props.onError();
		}

		if (coords && coords.status) {
			setShowError(false);
			setShowLocationsMenu(false);
			setValue(place.description);
			if (place.description) {
				const text = place.description;
				placesService?.getDetails({placeId: place.place_id},
					() => {
						props.saveAddress({
							city: place.structured_formatting.main_text,
							fullCity: place.structured_formatting.secondary_text.split(",")[0] || "Wien",
							lat: coords.lat,
							lng: coords.lng
						});
						props.displayOnlyCity
							? setValue(place.structured_formatting.main_text)
							: setValue(text)
					}
				);
			}
		}
	};

	useEffect(() => {
		if (props.defaultValue) {
			setValue(props.defaultValue)
		}
	}, [props.defaultValue])

	return(
		<div style={{position: "relative", width: "100%"}} >
			<input
				onBlur={() => props.onBlur && props.onBlur()}
				style={props.style}
				type="text"
				value={value}
				onChange={(evt) => {
					setShowLocationsMenu(true);
					getPlacePredictions({ input: evt.target.value });
					setValue(evt.target.value);
					if (!evt.target.value || evt.target.value.length < 1) {
						props.onInputCleared && props.onInputCleared();
					}
				}}
				data-testid="cy_google_autocomplete"
				placeholder={props.placeholder ? props.placeholder : props.displayOnlyCity
					? t("home.home.main_section.search.placeholder")
					: t("home.home.main_section.search.placeholder_address")}
				className={styles.google_autocomplete_input}
			/>
			{placePredictions.length && showLocationsMenu
				? !isPlacePredictionsLoading && (
				<div className={`${styles.google_autocomplete_input_list} ${props.withShadow ? styles.shadow : ""}`} >
					{placePredictions.map((place, idx) => (
						props.mobile
							? <div
								key={place.place_id}
								className={styles.google_autocomplete_input_list_item_mobile}
								onClick={() => onPlaceSelected(place, props.index)}
								data-testid={`search_list_item_${idx}`}
							>
								<div>
									<motion.img
										src={locationIcon.src}
										alt="search"
									/>
								</div>
								<p>{place.description}</p>
							</div>
							: <div
								key={place.place_id}
								className={styles.google_autocomplete_input_list_item}
								onClick={() => onPlaceSelected(place, props.index)}
								data-testid={`search_list_item_${idx}`}
							>
								{place.description}
							</div>
					))}
				</div>
			)
				: null
			}

			{showError
				? <p className={errorStyles.input_error}>
					{t("components.autocomplete.not_found_error")}
				</p>
				: null
			}

		</div>
	);
}
